/**
 * This file is generated using scripts/generate-language-select-options.js.
 * Update whenever we add new localizations.
 */

import type { SelectOption } from '@sumup-oss/circuit-ui';

export const options: SelectOption[] = [
  {
    'value': 'da-DK',
    'label': 'Dansk',
    'data-testid': 'da-DK',
    'key': 'da-DK',
  },
  {
    'value': 'de-DE',
    'label': 'Deutsch',
    'data-testid': 'de-DE',
    'key': 'de-DE',
  },
  {
    'value': 'et-EE',
    'label': 'Eesti',
    'data-testid': 'et-EE',
    'key': 'et-EE',
  },
  {
    'value': 'en-GB',
    'label': 'English',
    'data-testid': 'en-GB',
    'key': 'en-GB',
  },
  {
    'value': 'es-ES',
    'label': 'Español',
    'data-testid': 'es-ES',
    'key': 'es-ES',
  },
  {
    'value': 'fr-FR',
    'label': 'Français',
    'data-testid': 'fr-FR',
    'key': 'fr-FR',
  },
  {
    'value': 'hr-HR',
    'label': 'Hrvatski',
    'data-testid': 'hr-HR',
    'key': 'hr-HR',
  },
  {
    'value': 'it-IT',
    'label': 'Italiano',
    'data-testid': 'it-IT',
    'key': 'it-IT',
  },
  {
    'value': 'lv-LV',
    'label': 'Latviešu valoda',
    'data-testid': 'lv-LV',
    'key': 'lv-LV',
  },
  {
    'value': 'lt-LT',
    'label': 'Lietuviškai',
    'data-testid': 'lt-LT',
    'key': 'lt-LT',
  },
  {
    'value': 'hu-HU',
    'label': 'Magyar',
    'data-testid': 'hu-HU',
    'key': 'hu-HU',
  },
  {
    'value': 'nl-NL',
    'label': 'Nederlands',
    'data-testid': 'nl-NL',
    'key': 'nl-NL',
  },
  {
    'value': 'nb-NO',
    'label': 'Norsk',
    'data-testid': 'nb-NO',
    'key': 'nb-NO',
  },
  {
    'value': 'pl-PL',
    'label': 'Polski',
    'data-testid': 'pl-PL',
    'key': 'pl-PL',
  },
  {
    'value': 'pt-BR',
    'label': 'Português',
    'data-testid': 'pt-BR',
    'key': 'pt-BR',
  },
  {
    'value': 'ro-RO',
    'label': 'Română',
    'data-testid': 'ro-RO',
    'key': 'ro-RO',
  },
  {
    'value': 'sk-SK',
    'label': 'Slovenčina',
    'data-testid': 'sk-SK',
    'key': 'sk-SK',
  },
  {
    'value': 'sl-SI',
    'label': 'Slovenščina',
    'data-testid': 'sl-SI',
    'key': 'sl-SI',
  },
  {
    'value': 'fi-FI',
    'label': 'Suomi',
    'data-testid': 'fi-FI',
    'key': 'fi-FI',
  },
  {
    'value': 'sv-SE',
    'label': 'Svenska',
    'data-testid': 'sv-SE',
    'key': 'sv-SE',
  },
  {
    'value': 'cs-CZ',
    'label': 'Čeština',
    'data-testid': 'cs-CZ',
    'key': 'cs-CZ',
  },
  {
    'value': 'el-GR',
    'label': 'Ελληνικά',
    'data-testid': 'el-GR',
    'key': 'el-GR',
  },
  {
    'value': 'bg-BG',
    'label': 'Български',
    'data-testid': 'bg-BG',
    'key': 'bg-BG',
  },
];
