import type { FunctionComponent, ReactNode } from 'react';

export const Banner: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => (
  <div
    style={{
      backgroundColor: 'var(--cui-fg-warning)',
      padding: 'var(--cui-spacings-kilo)',
    }}
  >
    <span
      style={{
        marginTop: 'var(--cui-spacings-kilo)',
        marginBottom: 'var(--cui-spacings-kilo)',
      }}
    >
      {children}
    </span>
  </div>
);
