import { useEffect, useState } from 'react';

interface Summary {
  summary: Status;
}

interface Status {
  ongoing_incidents: Incident[];
  scheduled_maintenances: Maintenance[];
}

export interface Incident {
  id: string; // e.g. "01H0QJ89CRW5BKTY9N89MSDT5S"
  name: string; // e.g. "Login failing for some users"
  status: string; // "identified" | "identified" | "monitoring"
  url: string; // e.g. "https://status.sumup.com/incidents/01H0QJ89CRW5BKTY9N89MSDT5S"
  last_updated_at: string; // e.g. "2023-07-27T11:30:37.180Z"
  last_update_message?: string;
  current_worst_impact: string; // "partial_outage" | "degraded_performance" | "full_outage"
  affected_components?: Component[];
}

export interface Maintenance {
  id: string; // e.g. "01FDAG4SAP5TYPT98WGR2N7W91"
  name: string; // e.g. "Database upgrade"
  status: string; // 'scheduled' | 'in_progress'
  url: string; // e.g. "https://status.sumup.com/incidents/01FDAG4SAP5TYPT98WGR2N7W91"
  last_updated_at: string; // e.g. "2023-07-27T11:30:37.180Z"
  last_update_message?: string;
  affected_components?: Component[];
  started_at?: string; // used when in progress, e.g. "2023-07-27T11:30:37.180Z"
  scheduled_end_at?: string; // used when in progress, e.g. "2023-07-27T11:30:37.180Z"
  starts_at?: string; // used when scheduled, e.g. "2023-07-27T11:30:37.180Z"
  ends_at?: string; // used when scheduled, e.g. "2023-07-27T11:30:37.180Z"
}

interface Component {
  id: string; // e.g. "01HCAAQEMM4WKFH3MZFDCD803Z"
  name: string; // e.g. "Logins"
}

export const useStatus = (): Status => {
  const [status, setStatus] = useState<Status>({
    ongoing_incidents: [],
    scheduled_maintenances: [],
  });

  useEffect(() => {
    const loadStatus = async (): Promise<Status> => {
      const resp = await fetch(
        'https://status.sumup.com/proxy/status.sumup.com',
      );
      const summary = (await resp.json()) as Summary;
      return summary.summary;
    };

    loadStatus()
      .then((currentStatus) => setStatus(currentStatus))
      .catch(() => {});
  }, []);

  return status;
};

export const useIncidents = (): Incident[] => {
  const { ongoing_incidents: ongoingIncidents } = useStatus();
  return ongoingIncidents;
};

export const useScheduledMaintenances = (): Maintenance[] => {
  const { scheduled_maintenances: scheduledMaintenances } = useStatus();
  return scheduledMaintenances;
};
