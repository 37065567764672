export const ROOT_SELECTOR = 'Auth';

export const GRID_VALUES = {
  SPAN: {
    untilKilo: 12,
    kilo: 10,
    mega: 8,
    giga: 6,
    tera: 4,
    peta: 4,
  },
  SKIP: {
    untilKilo: 0,
    kilo: 1,
    mega: 2,
    giga: 3,
    tera: 4,
    peta: 4,
  },
  LEFT_COLUMN_SPAN: {
    untilKilo: 12,
    kilo: 10,
    mega: 6,
    giga: 5,
    tera: 5,
    peta: 5,
  },
  RIGHT_COLUMN_SPAN: {
    untilKilo: 12,
    kilo: 12,
    mega: 6,
    giga: 5,
    tera: 4,
    peta: 4,
  },
  LEFT_COLUMN_SKIP: {
    untilKilo: 0,
    kilo: 1,
    mega: 0,
    giga: 1,
    tera: 1,
    peta: 1,
  },
  RIGHT_COLUMN_SKIP: {
    untilKilo: 0,
    kilo: 0,
    mega: 6,
    giga: 6,
    tera: 7,
    peta: 6,
  },
};
