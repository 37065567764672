import { Body } from '@sumup-oss/circuit-ui';
import { Notify } from '@sumup-oss/icons';
import type { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import type { Maintenance as MaintenanceT } from '../../services/incidents';

import { Banner } from './Banner';

interface MaintenanceProps {
  maintenance: MaintenanceT;
}

export const Maintenance: FunctionComponent<MaintenanceProps> = ({
  maintenance,
}) => {
  const intl = useIntl();

  const startAt = maintenance?.started_at || maintenance?.starts_at;
  const start = startAt ? new Date(startAt) : undefined;
  const endAt = maintenance?.scheduled_end_at || maintenance?.ends_at;
  const end = endAt ? new Date(endAt) : undefined;

  if (!start || !end) {
    return null;
  }

  let message = '';

  const scheduled = maintenance.status === 'scheduled';

  const sameDay =
    start?.getFullYear() === end?.getFullYear() &&
    start?.getMonth() === end?.getMonth() &&
    start?.getDate() === end?.getDate();

  if (!scheduled) {
    message = intl.formatMessage(
      {
        defaultMessage:
          'Maintenance of SumUp Services is ongoing until {end}. Our services may be unavailable during this time.',
        description:
          'Message shown in a banner at the top of the page if there is an ongoing maintenance.',
      },
      {
        end: end.toLocaleTimeString([], {
          hour: 'numeric',
          minute: 'numeric',
        }),
      },
    );
  } else if (sameDay) {
    message = intl.formatMessage(
      {
        defaultMessage:
          'Maintenance of SumUp Services is scheduled on {date} between {start} and {end}. Our services may be unavailable during this time.',
        description:
          'Message shown in a banner at the top of the page if there is a scheduled maintenance.',
      },
      {
        date: start.toLocaleDateString(),
        start: start.toLocaleTimeString([], {
          hour: 'numeric',
          minute: 'numeric',
        }),
        end: end.toLocaleTimeString([], {
          hour: 'numeric',
          minute: 'numeric',
        }),
      },
    );
  } else {
    message = intl.formatMessage(
      {
        defaultMessage:
          'Maintenance of SumUp Services is scheduled between {startDate}, {startTime} and {endDate}, {endTime}. Our services may be unavailable during this time.',
        description:
          'Message shown in a banner at the top of the page if there is a scheduled maintenance.',
      },
      {
        startDate: start.toLocaleDateString(),
        startTime: start.toLocaleTimeString([], {
          hour: 'numeric',
          minute: 'numeric',
        }),
        endDate: end.toLocaleDateString(),
        endTime: end.toLocaleTimeString([], {
          hour: 'numeric',
          minute: 'numeric',
        }),
      },
    );
  }
  return (
    <Banner>
      <Notify
        style={{ float: 'left', marginRight: 'var(--cui-spacings-kilo)' }}
        size="24"
      />
      <Body>{message}</Body>
    </Banner>
  );
};
