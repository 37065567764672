import { Body } from '@sumup-oss/circuit-ui';
import { Notify } from '@sumup-oss/icons';
import Link from 'next/link';
import type { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { Banner } from './Banner';

export const Incident: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <Banner>
      <Notify
        style={{
          float: 'left',
          marginRight: 'var(--cui-spacings-kilo)',
        }}
        size="24"
      />
      <Body>
        {intl.formatMessage(
          {
            defaultMessage:
              "There's currently an outage affecting one or more SumUp services. We're working to fix this. Please check the {link} for more details.",
            description:
              'Message shown in a banner at the top of the page if there is an ongoing incident.',
          },
          {
            link: (
              <Link href="https://status.sumup.com/" passHref>
                {intl.formatMessage({
                  defaultMessage: 'service status page',
                  description: 'Text of the status page link.',
                })}
              </Link>
            ),
          },
        )}
      </Body>
    </Banner>
  );
};
