import type { FunctionComponent } from 'react';

import { useStatus } from '../../services/incidents';

import { Incident } from './Incident';
import { Maintenance } from './Maintenance';

export const Status: FunctionComponent = () => {
  const loginsComponentID = '01HCAAQEMM4WKFH3MZFDCD803Z';

  const status = useStatus();

  const ongoingIncidents = status?.ongoing_incidents.filter(
    ({ affected_components }) =>
      // Only show incidents that affect Auth & Logins.
      affected_components?.some(({ id }) => id === loginsComponentID),
  );
  if (ongoingIncidents && ongoingIncidents.length > 0) {
    return <Incident />;
  }

  const scheduledMaintenances = status?.scheduled_maintenances.filter(
    ({ affected_components }) =>
      // Only show maintenances that affect Auth & Logins.
      affected_components?.some(({ id }) => id === loginsComponentID),
  );
  if (scheduledMaintenances && scheduledMaintenances.length > 0) {
    // find the maintenance with the earliest start date
    const maintenance = scheduledMaintenances.reduce((prev, current) => {
      const prevStart = new Date(prev.starts_at ?? 0);
      const currentStart = new Date(current.starts_at ?? 0);
      return prevStart.getMilliseconds() > 0 && prevStart < currentStart
        ? prev
        : current;
    });
    if (maintenance) {
      return <Maintenance maintenance={maintenance} />;
    }
  }

  return null;
};
